import {
  AspectRatio,
  Box,
  IconButton,
  Modal,
  ModalClose,
  Sheet,
  Typography,
} from "@mui/joy";
import { ILotto } from "../../../../../models";
import { useEffect, useMemo, useRef, useState } from "react";
import { BASE_URL, IMAGE_LABEL_FORMULA } from "../../../../../config";
import { getImageName } from "../../../../../lib/functions";
import Carousel from "react-simply-carousel";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeft from "@mui/icons-material/ChevronLeft";

const IMAGE_URL = `${BASE_URL}image/`;

export interface ICarouselDialogProps {
  lotto: ILotto;
  selectedImageId: number | null;
  isOpen: boolean;
  onClose: () => void;
}

function CarouselDialog({
  lotto,
  selectedImageId,
  isOpen,
  onClose,
}: ICarouselDialogProps) {
  const [activeSlide, setActiveSlide] = useState(0);
  const [height, setHeight] = useState<number>();
  const [width, setWidth] = useState<number>();
  const [imageHeight, setImageHeight] = useState<number>();
  const carousel = useRef<HTMLElement>();

  const images = useMemo(
    () => lotto.images?.sort((a, b) => a.slab - b.slab) ?? [],
    [lotto]
  );
  
  const getSize = () => {
    const newHeight = carousel?.current?.clientHeight;
    const newWidth = carousel?.current?.clientWidth;
    setHeight(newHeight);
    setWidth(newWidth);
    setImageHeight((newHeight ?? 0) - 48);
  };
  useEffect(() => {
    if (isOpen) window.addEventListener("resize", getSize);
    else window.removeEventListener("resize", getSize);
    setTimeout(getSize, 500);
  }, [isOpen]);
  useEffect(() => {
    setActiveSlide(
      lotto.images?.findIndex((image) => image.id === selectedImageId) ?? 0
    );
  }, [lotto, selectedImageId]);
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: "80%",
          height: "80%",
          borderRadius: "md",
          p: 6,
          boxShadow: "lg",
        }}
      >
        <ModalClose variant="outlined" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            position: "relative",
          }}
          ref={carousel}
        >
          {!!height && (
            <Carousel
              containerProps={{ style: { flex: 1 } }}
              activeSlideIndex={activeSlide}
              onRequestChange={setActiveSlide}
              itemsToScroll={1}
              itemsToShow={1}
              forwardBtnProps={{
                //here you can also pass className, or any other button element attributes
                style: {
                  position: "absolute",
                  right: 0,
                  alignSelf: "center",
                  background: "black",
                  border: "none",
                  borderRadius: "50%",
                  textAlign: "center",
                  width: 60,
                  height: 60,
                  padding: 2,
                },
                children: (
                  <IconButton
                    sx={{
                      "--IconButton-size": "50px",
                      borderRadius: "50%",
                    }}
                  >
                    <ChevronRight />
                  </IconButton>
                ),
              }}
              backwardBtnProps={{
                //here you can also pass className, or any other button element attributes
                style: {
                  zIndex:10,
                  position: "absolute",
                  left: 0,
                  alignSelf: "center",
                  background: "black",
                  border: "none",
                  borderRadius: "50%",
                  textAlign: "center",
                  width: 60,
                  height: 60,
                  padding: 2,
                },
                children: (
                  <IconButton
                    sx={{
                      "--IconButton-size": "50px",
                      borderRadius: "50%",
                    }}
                  >
                    <ChevronLeft />
                  </IconButton>
                ),
              }}
            >
              {images.map((image, index) => (
                <Box key={index} sx={{ height, width }}>
                  <AspectRatio
                    sx={{ width }}
                    objectFit="contain"
                    minHeight={imageHeight}
                    maxHeight={imageHeight}
                  >
                    <img
                      src={`${IMAGE_URL}${image.hdPath}`}
                      alt={`image_${index}`}
                    />
                  </AspectRatio>
                  <Typography
                    sx={{
                      mb: 2,
                      textAlign: "center",
                      "@media screen and (min-width: 992px)": {
                        display: "none",
                      },
                    }}
                    level="title-sm"
                  >
                    {lotto
                      ? getImageName(image, lotto, IMAGE_LABEL_FORMULA)
                      : ""}
                  </Typography>
                  <Typography
                    sx={{
                      mb: 2,
                      textAlign: "center",
                      display: "none",
                      "@media screen and (min-width: 992px)": {
                        display: "block",
                      },
                    }}
                    level="h1"
                  >
                    {lotto
                      ? getImageName(image, lotto, IMAGE_LABEL_FORMULA)
                      : ""}
                  </Typography>
                </Box>
              ))}
            </Carousel>
          )}
        </Box>
      </Sheet>
    </Modal>
  );
}

export default CarouselDialog;
