import { Box, Link, Typography } from "@mui/joy";
import { useMemo, useState } from "react";
import { ILotto } from "../../../models";
import DettaglioPrintDialog from "./dialogs/print/printDialog";

interface ILottoInformationsProps {
  lotto: ILotto | null;
  onBack: () => void;
}

export default function LottoInformations({
  lotto,
  onBack,
}: ILottoInformationsProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const numeroLastre = lotto?.dettagli?.reduce(
    (prev, dett) => dett.pezzi + prev,
    0
  );
  const hasOptioned = useMemo(
    () =>
      !!lotto?.dettagli?.some(
        (item) => item.idDisponibilita && item.idDisponibilita > 1
      ),
    [lotto]
  );
  const onClick = () => {
    setIsOpen(true);
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        position: "relative",
        pb: 1,
        boxSizing: "border-box",
        backgroundColor: "rgba(0,0,0,.20)",
      }}
    >
      <Box sx={{ px: 4, pt: 1, width: "100%", boxSizing: "border-box" }}>
        <Typography
          level="body-md"
          onClick={onBack}
          sx={{
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          &lt; indietro
        </Typography>
        <Box sx={{ display: "flex", py: 2 }}>
          <Typography sx={{ mt: 2 }} level="h4">
            {lotto?.codice}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", py: 1 }}>
          <Typography>Tipologia:</Typography>
          <Typography sx={{ ml: 2, color: "#fff" }}>
            {lotto?.materiale?.nome}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", py: 1 }}>
          <Typography>Materiale:</Typography>
          <Typography sx={{ ml: 2, color: "#fff" }}>
            {lotto?.qualita?.nome}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", py: 1 }}>
          <Typography>Lastre:</Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ ml: 2, color: "#fff" }}>
              Tot. {numeroLastre}
            </Typography>
            <Box sx={{}}>
              {lotto?.dettagli?.map((dett, index) => (
                <Box key={index} sx={{ ml: 2, display: "flex" }}>
                  <Typography
                    sx={{ color: "#fff", width: "50px" }}
                  >{`${dett.pezzi}`}</Typography>
                  <Typography sx={{ color: "#fff", width: "160px" }}>
                    {`${dett.misX} x ${dett.misY} x ${dett.misZ} cm`}
                  </Typography>
                  <Typography
                    sx={{ color: "#fff", width: "60px" }}
                  >{`${dett.stato_lavorazione?.nome}`}</Typography>
                  {!!dett.idDisponibilita && dett.idDisponibilita > 1 && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "16px",
                          height: "16px",
                          borderRadius: "50px",
                          backgroundColor: "#fbdb47",
                        }}
                      ></Box>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        {hasOptioned && (
          <Box sx={{ marginTop: 4, display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "50px",
                  backgroundColor: "#fbdb47",
                }}
              ></Box>
            </Box>

            <Typography level="body-xs" sx={{ color: "#fff", marginLeft: 2 }}>
              Opzionati
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ px: 4, pt: 4, width: "100%", boxSizing: "border-box" }}>
        <Link
          sx={{ ":hover": { background: "none" } }}
          onClick={onClick}
          variant="plain"
        >
          RICHIEDI PACKING LIST
        </Link>
      </Box>

      {lotto && (
        <DettaglioPrintDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          lotto={lotto}
        ></DettaglioPrintDialog>
      )}
    </Box>
  );
}
