export const isString = (object: unknown): object is string =>
  typeof object === "string" || object instanceof String;

export const isEmpty = (val: any): val is undefined | null =>
  val === undefined || val === null;

export const isNotEmpty = <T>(val: T | undefined | null): val is T =>
  !isEmpty(val);

export const matrixify = <T>(arr: T[], size: number): T[][] =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

export const searchInSource = (source: any, match: string): string => {
  const field = match.substring(1, match.length - 1);
  const keys = field.split(".");
  let value: any = source;
  while (keys.length) {
    const key = keys.shift();
    if (!!key) value = value[key];
    if (!value) return "";
  }
  return value?.toString() ?? "";
};

export const capitalizeText = (text: string): string =>
  text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
