import { Box, Typography } from "@mui/joy";
import { GridChildComponentProps } from "react-window";
import mockBackground from "../../../assets/background.jpg";
import { BASE_URL } from "../../../config";
import { IQualita } from "../../../models";
import { capitalizeText } from "../../../lib/utils";

const IMAGE_URL = `${BASE_URL}qualitaImage/`;

export interface IGridCellProps extends GridChildComponentProps<IQualita[][]> {
  onView: (materiale: IQualita) => void;
}

export const MaterialeGridCell = ({
  columnIndex,
  rowIndex,
  style,
  data,
  onView,
}: IGridCellProps) => {
  const item = data[rowIndex][columnIndex];
  if (!item) return null;
  const background = item.image
    ? `${IMAGE_URL}${item.image.mdPath}`
    : mockBackground;
  return (
    <Box
      style={style}
      sx={{
        boxSizing: "border-box",
        position: "relative",
        padding: 2,
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url('${background}')`,
            flex: 1,
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></Box>
        <Typography level="body-lg" sx={{ color: "rgb(86, 185, 58)" }}>{capitalizeText(item.nome)}</Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          backgroundColor: "rgba(86, 185, 58, 0.7)",
          opacity: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          transition: "opacity .15s ease-in-out",
          "&:hover": {
            opacity: 0.3,
          },
        }}
        onClick={() => onView(item)}
      ></Box>
    </Box>
  );
};
