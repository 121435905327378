import { CSSProperties, forwardRef, useMemo } from "react";
import logo from "../../../../../assets/logo.png";
import {
  getTotalMQ,
  getTotalNumberOfSlabs,
} from "../../../../../lib/functions";
import { ILotto } from "../../../../../models";
import { Box, Typography } from "@mui/joy";
export interface IPackingListToPrintProps {
  lotto: ILotto;
}

const tdStyle: CSSProperties = {
  border: "1px solid grey",
  padding: "10px",
  textAlign: "center",
};
const thStyle: CSSProperties = {
  fontWeight: "bold",
  border: "1px solid grey",
  padding: "10px",
  textAlign: "center",
  backgroundColor: "lightGrey",
};
const thStyleIcon: CSSProperties = {
  fontWeight: "bold",
  border: "1px solid grey",
  padding: "10px",
  textAlign: "center",
  backgroundColor: "lightGrey",
  width: "20px",
};

const PackingListToPrint = forwardRef<
  HTMLDivElement | null,
  IPackingListToPrintProps
>(({ lotto }, ref) => {
  const totalSlabs = useMemo(
    () => getTotalNumberOfSlabs(lotto.dettagli ?? []),
    [lotto]
  );
  const totalDimensions = useMemo(
    () => getTotalMQ(lotto.dettagli ?? [], 2, 2),
    [lotto]
  );
  const hasOptioned = useMemo(
    () =>
      !!lotto.dettagli?.some(
        (item) => item.idDisponibilita && item.idDisponibilita > 1
      ),
    [lotto]
  );
  return (
    <Box
      ref={ref}
      sx={{
        width: "100%",
        "@media print": { width: "210mm", fontSize: "12px", p: 4 },
      }}
    >
      <Box
        sx={{
          display: "none",
          "@media print": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <img src={logo} alt="logo" height={120} />
      </Box>
      <Box sx={{ p: 2, display: "none", "@media print": { display: "block" } }}>
        <div>
          <h5>PETRIS S.R.L. (Soc. Uninominale)</h5>
        </div>
        <div>Via Massa - Avenza, snc</div>
        <div>54100 MASSA (ITALY)</div>
        <div>P.I. 01234010450</div>
        <div>Tel. 390585093900</div>
        <div>Mail. info@petris.it</div>
        <div>Web. www.petris.it</div>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography
          level="h4"
          color="neutral"
          sx={{
            "@media print": { fontSize: "36px" },
          }}
        >
          Packing List
        </Typography>
      </Box>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={thStyle}>Materiale</th>
            <th style={thStyle}>Finitura</th>
            <th style={thStyle}>Lotto</th>
            <th style={thStyle}>Lastre</th>
            {hasOptioned && <th style={thStyleIcon}></th>}
            <th style={thStyle}>Pezzi</th>
            <th style={thStyle} colSpan={3}>
              Misure (CM)
            </th>
            <th style={thStyle}>M2</th>
          </tr>
        </thead>
        <tbody>
          {lotto.dettagli?.map((item) => (
            <tr key={item.id}>
              <td style={tdStyle}>{lotto?.qualita?.nome}</td>
              <td style={tdStyle}>{item?.stato_lavorazione?.nome}</td>
              <td style={tdStyle}>{lotto.codice}</td>
              <td style={tdStyle}>
                {item.lastreDa} / {item.lastreA}
              </td>
              {hasOptioned && (
                <td style={tdStyle}>
                  {!!item.idDisponibilita && item.idDisponibilita > 1 && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "16px",
                          height: "16px",
                          borderRadius: "50px",
                          backgroundColor: "#fbdb47",
                        }}
                      ></Box>
                    </Box>
                  )}
                </td>
              )}
              <td style={tdStyle}>{item.pezzi}</td>
              <td style={tdStyle}>{item.misX}</td>
              <td style={tdStyle}>{item.misY}</td>
              <td style={tdStyle}>{item.misZ}</td>
              <td style={tdStyle}>{item.quantitaUM}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            {hasOptioned && (
              <td>
                <Box sx={{ display: "flex" }}>
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "16px",
                        height: "16px",
                        borderRadius: "50px",
                        backgroundColor: "#fbdb47",
                      }}
                    ></Box>
                  </Box>
                  <Typography sx={{ ml: 2 }}>opzionato</Typography>
                </Box>
              </td>
            )}
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <th style={thStyle}>{totalSlabs}</th>
            <td></td>
            <td></td>
            <td></td>
            <th style={thStyle}>{totalDimensions}</th>
          </tr>
        </tfoot>
      </table>
    </Box>
  );
});

export default PackingListToPrint;
