import { Box } from "@mui/joy";
import { useEffect, useState } from "react";
import { getDettaglioList } from "../../fetch";
import { ILotto } from "../../models";
import { useNavigate, useParams } from "react-router-dom";
import LottoInformations from "./components/LottoInformations";
import LottoImages from "./components/LottoImages";

export default function Item() {
  const { id } = useParams();
  const navigate = useNavigate();
  const onBack = () => navigate(`..`);
  const lottoId = !!id ? parseInt(id) : null;
  const [lotto, setLotto] = useState<ILotto | null>(null);

  useEffect(() => {
    let mounted = true;
    getDettaglioList().then((result) => {
      if (mounted) {
        const dettagli = result.items.filter(
          (item) => item.idLotto === lottoId
        );
        dettagli.sort((itemA, itemB) => itemA.lastreDa - itemB.lastreA);
        const lotto = dettagli?.[0].lotto;
        if (lotto) {
          lotto.dettagli = dettagli;
          setLotto(lotto);
        }
      }
    });
    return () => {
      mounted = false;
    };
  }, [lottoId]);
  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            boxSizing: "border-box",
            width: "100%",
            overflow: "auto",
            "@media screen and (min-width: 992px)": {
              flexDirection: "row",
              pl: 4,
            },
          }}
        >
          <Box
            sx={{
              py: 1,
              "@media screen and (min-width: 992px)": {
                py: 0,
                display: "flex",
                height: "100%",
                width: "25%",
              },
            }}
          >
            <LottoInformations lotto={lotto} onBack={onBack} />
          </Box>
          <Box
            sx={{
              position: "relative",
              "@media screen and (min-width: 992px)": { width: "75%" },
            }}
          >
            <LottoImages lotto={lotto} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
