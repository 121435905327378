import {
  Box,
  FormControl,
  FormLabel,
  Link,
  Option,
  Select
} from "@mui/joy";
import { useEffect, useState } from "react";
import { IStatoLavorazione } from "../../../models";
import { EOptionType, IFilterOption } from "../../../models/utlis";

interface ISelectLottoFiltersProps {
  lavorazioni: IStatoLavorazione[];
  spessoriDisponibili: number[];
  filters: IFilterOption[];
  onSetFilters: (filters: IFilterOption[]) => void;
}

export default function SelectLottoFilters({
  spessoriDisponibili,
  lavorazioni,
  filters,
  onSetFilters,
}: ISelectLottoFiltersProps) {
  const [selectedLavorazione, setSelectedLavorazione] = useState<string | null>(
    null
  );
  const [selectedSpessore, setSelectedSpessore] = useState<string | null>(null);

  const onChangeLavorazione = (
    _event:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element, Element>
      | null,
    value: string | null
  ) => {
    const _filters = [...filters].filter(
      (opt) => !(opt.type === EOptionType.statoLavorazione)
    );
    if (value) {
      onSetFilters([
        ..._filters,
        {
          type: EOptionType.statoLavorazione,
          numericValue: parseInt(value),
        },
      ]);
    } else {
      onSetFilters(_filters);
    }
  };
  const onSelectedSpessore = (
    _event:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element, Element>
      | null,
    value: string | null
  ) => {
    const _filters = [...filters].filter(
      (opt) => !(opt.type === EOptionType.misZ)
    );
    if (value) {
      onSetFilters([
        ..._filters,
        {
          type: EOptionType.misZ,
          numericValues: [parseFloat(value), parseFloat(value)],
        },
      ]);
    } else {
      onSetFilters(_filters);
    }
  };

  const onResetFilters = () => {
    const _filters = [...filters].filter(
      (opt) =>
        ![EOptionType.statoLavorazione, EOptionType.misZ].includes(opt.type)
    );
    onSetFilters(_filters);
  };

  useEffect(() => {
    const finitura =
      filters.find((filter) => filter.type === EOptionType.statoLavorazione)
        ?.numericValue ?? "";
    const misZArray =
      filters.find((filter) => filter.type === EOptionType.misZ)
        ?.numericValues ?? "";

    setSelectedLavorazione(finitura ? finitura.toString() : null);
    setSelectedSpessore(misZArray?.[0] ? misZArray[0].toString() : null);
  }, [filters]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0,0,0,.20)",
      }}
    >
      <Box
        sx={{
          px: 1,
          "@media screen and (min-width: 992px)": {
            px: 4,
            py: 16,
          },
        }}
      >
        <FormControl sx={{ py: 1 }}>
          <FormLabel color="primary">FINITURA</FormLabel>
          <Select
            placeholder="Finitura…"
            variant="plain"
            value={selectedLavorazione}
            onChange={onChangeLavorazione}
          >
            <Option value="">---</Option>
            {lavorazioni.map((lavorazione) => (
              <Option key={lavorazione.id} value={lavorazione.id.toString()}>
                {lavorazione.nome}
              </Option>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ py: 1 }}>
          <FormLabel color="primary">SPESSORE</FormLabel>
          <Select
            placeholder="Spessore..."
            variant="plain"
            value={selectedSpessore}
            onChange={onSelectedSpessore}
          >
            <Option value="">---</Option>
            {spessoriDisponibili.map((misZ) => (
              <Option key={misZ} value={misZ.toString()}>
                {misZ}
              </Option>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ py: 2, display: "flex", justifyContent: "space-between" }}>
          <Link
            sx={{ ":hover": { background: "none" } }}
            onClick={onResetFilters}
             variant="plain"
          >
            RESET FILTRI
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
